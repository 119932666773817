import { BuilderHttp } from '../../../seguranca/builder-http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class UfService {

  ufUrlAPI = `${environment.apiUrl}/api/estados`;
  
  constructor(
    private httpBuilder: BuilderHttp
    ) { }


  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.ufUrlAPI)
      .toPromise();
  }

  listarTodasCidades(uf: string): Promise<any> {
    return this.httpBuilder.get(`${this.ufUrlAPI}/${uf}/cidades`)
      .toPromise();
  }

}
