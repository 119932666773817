import { BuilderHttp } from '../../../seguranca/builder-http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class SetorService {

  setorUrlAPI = `${environment.apiUrl}/api/setores`;
  
  constructor(
    private httpBuilder: BuilderHttp
    ) { }


  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.setorUrlAPI)
      .toPromise();
  }
}
