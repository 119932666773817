import { BuilderHttp } from '../../../seguranca/builder-http';
import { HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { InstituicaoSaude, InstituicaoSaudeFiltro } from '../../../core/models';

@Injectable()
export class InstituicaoSaudeService {

  statusInstituicoesSaude = [
    { 'Ativo' : 'ATIVO' },
    { 'Bloqueado' : 'BLOQUEADO' }
  ];

  servicosSaude = [
    { label: 'Unidade de Internação', value: 'INTERNACAO' },
    { label: 'Serviço Médico', value: 'MEDICO' },
    { label: 'Serviço Cirúrgico', value: 'CIRURGICO' },
    { label: 'Centro Obstétrico', value: 'OBSTETRICO' },
    { label: 'Centro Neonatologia', value: 'NEONATOLOGIA' },
    { label: 'UTI Neonatal', value: 'UTI_NEONATAL' },
    { label: 'Centro Diagnóstico e Imagem', value: 'DIAGNOSTICO_IMAGEM' }
  ];

  instituicaoSaudeUrlAPI = `${environment.apiUrl}/api/instituicoessaude`;

  constructor(
    private httpBuilder: BuilderHttp
  ) { }

  getPesquisar(filtro: InstituicaoSaudeFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.id) {
      params = params.append('id', filtro.id);
    }

    if (filtro.razaoSocial) {
      params = params.append('razaoSocial', filtro.razaoSocial);
    }

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/page?`,
      { params })
      .toPromise()
      .then(response => {
        const instituicoesSaude = response.content;

        for (let i = 0; i < instituicoesSaude.length; i++) {

          this.statusInstituicoesSaude.forEach(function(objeto) {
            for ( var chave in objeto )
                if (objeto[chave] === instituicoesSaude[i].statusInstituicaoSaude) {
                  instituicoesSaude[i].statusInstituicaoSaude = chave;
                }
          });
          this.servicosSaude.forEach(function(objeto) {
            for ( var chave in objeto )
                if (objeto[chave] === instituicoesSaude[i].servicoSaude) {
                  instituicoesSaude[i].servicoSaude = chave;
                }
          });

        }
        const resultado = {
          instituicoesSaude,
          total: response.totalElements
        };

        return resultado;
      });
  }

  excluir(id: number): Promise<void> {
    return this.httpBuilder
      .delete(`${this.instituicaoSaudeUrlAPI}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(instituicaoSaude: InstituicaoSaude): Promise<InstituicaoSaude> {
    return this.httpBuilder
      .post<InstituicaoSaude>(this.instituicaoSaudeUrlAPI, instituicaoSaude)
      .toPromise()
     // .then(response => response)
  }

  atualizar(instituicaoSaude: InstituicaoSaude): Promise<InstituicaoSaude> {
    return this.httpBuilder
      .put<InstituicaoSaude>(`${this.instituicaoSaudeUrlAPI}/${instituicaoSaude.id}`, instituicaoSaude)
      .toPromise()
  }

  buscarPorId(id: number): Promise<InstituicaoSaude> {
    return this.httpBuilder.get<InstituicaoSaude>(`${this.instituicaoSaudeUrlAPI}/${id}`)
      .toPromise();
  }

  mudarStatus(codigo: number, ativo: number): Promise<void> {

    return this.httpBuilder.put(`${this.instituicaoSaudeUrlAPI}/${codigo}/ativo`, ativo)
      .toPromise()
      .then(() => null);
  }

  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.instituicaoSaudeUrlAPI)
      .toPromise();
  }

  listarTodasDto(): Promise<any> {

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/listarTodasDto`)
      .toPromise()
  }

  listarTodasEspecialidadesAtivas(): Promise<any> {

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/buscarTodasEspecialidadesAtivas`)
      .toPromise()
      .then(response => response);
  }

  getCarregarVagas(filtro: InstituicaoSaudeFiltro): Promise<string> {

    let params = new HttpParams();

    params = params.append('especialidade', filtro.especialidade);

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/vagasTotais?`, { params })
      .toPromise()
  }

  //TODO
  getBuscaInstituicoesSaude(filtro: InstituicaoSaudeFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.especialidade) {
      params = params.append('especialidade', filtro.especialidade);
    }
    if (filtro.razaoSocial) {
      params = params.append('instituicaoSaude', filtro.razaoSocial);
    }

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/page/buscaParaAgendamento?`,
      { params })
      .toPromise()
      .then(response => {
        const instituicoesSaude = response.content;
        const resultado = {
          instituicoesSaude,
          total: response.totalElements
        };
        return resultado;
      });
  }

  getPesquisarParaAgendamento(filtro: InstituicaoSaudeFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.especialidade) {
      params = params.append('especialidade', filtro.especialidade);
    }
    if (filtro.razaoSocial) {
      params = params.append('instituicaoSaude', filtro.razaoSocial);
    }

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/page/buscaInstituicaoSaudeResumo?`,
      { params })
      .toPromise()
      .then(response => {
        const instituicoesSaude = response.content;
        const resultado = {
          instituicoesSaude,
          total: response.totalElements
        };

        return resultado;
      });
  }

  gerarRelatorioCredenciados(status: string) {

    let params = new HttpParams();
    params = params.append('status', status);

    return this.httpBuilder.get<any>(`${this.instituicaoSaudeUrlAPI}/gerarRelatorioConveniadosAtivos`,
      { responseType: 'blob', params})
      .toPromise();

  }


}
