import { Injectable } from '@angular/core';
import { BuilderHttp } from '../../seguranca/builder-http';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class RelatoriosService {

  relatorioCarteiraUrlApi: string

  constructor(private http: BuilderHttp) {
    this.relatorioCarteiraUrlApi = `${environment.apiUrl}/api/carteiras`;
  }

  imprimirCarteira(id: string, tipo: string, tipoPrinter: string): Promise<any> {

    let params = new HttpParams();
    params = params.append('tipo', tipo);
    params = params.append('tipoPrinter', tipoPrinter);

    return this.http.get<any>(`${this.relatorioCarteiraUrlApi}/imprimirCarteira/${id}`, 
      { responseType: 'blob', params })
      .toPromise();
  }

  imprimirCadastroTitular(id: string): Promise<any> {
    return this.http.get(`${this.relatorioCarteiraUrlApi}/imprimeCadastroTitular/${id}`,
      { responseType: 'blob' })
      .toPromise();
  }

  imprimirCadastroDependente(id: string): Promise<any> {
    return this.http.get(`${this.relatorioCarteiraUrlApi}/imprimeCadastroDependente/${id}`,
      { responseType: 'blob' })
      .toPromise();
  }

}
