import { BuilderHttp } from '../../../seguranca/builder-http';
import { HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { Conselho, ConselhoFiltro } from '../../../core/models';

@Injectable()
export class ConselhoService {
    
  conselhos: Conselho[];
  conselho: Conselho;
  conselhoUrlAPI = `${environment.apiUrl}/api/conselhosclasse`;
  
  constructor(
    private httpBuilder: BuilderHttp
  ) {  }

  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.conselhoUrlAPI)
      .toPromise();
  }

  getPesquisar(filtro: ConselhoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.id) {
      params = params.append('id', filtro.id);
    }

    if (filtro.descricao) {
      params = params.append('descricao', filtro.descricao);
    }

    if (filtro.sigla) {
      params = params.append('sigla', filtro.sigla);
    }

    return this.httpBuilder.get<any>(`${this.conselhoUrlAPI}/page?`,
      { params })
      .toPromise()
      .then(response => {
        const conselhos = response.content;

        const resultado = {
          conselhos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  excluir(id: number): Promise<void> {
    return this.httpBuilder
      .delete(`${this.conselhoUrlAPI}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(conselho: Conselho): Promise<Conselho> {
    return this.httpBuilder
      .post<Conselho>(this.conselhoUrlAPI, conselho)
      .toPromise()
      .then(response => response)
  }

  atualizar(conselho: Conselho): Promise<Conselho> {
    return this.httpBuilder
      .put<Conselho>(`${this.conselhoUrlAPI}/${conselho.id}`, conselho)
      .toPromise()
  }

  buscarPorId(id: number): Promise<Conselho> {
    return this.httpBuilder.get<Conselho>(`${this.conselhoUrlAPI}/${id}`)
      .toPromise();
  }

  mudarStatus(codigo: number, ativo: number): Promise<void> {

    return this.httpBuilder.put(`${this.conselhoUrlAPI}/${codigo}/ativo`, ativo)
      .toPromise()
      .then(() => null);
  }

}