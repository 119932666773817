import { Injectable } from '@angular/core';

import { environment } from './../../environments/environment';
import { BuilderHttp } from './builder-http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class LogoutService {

  tokensRenokeUrl: string;

  constructor(
    private http: BuilderHttp,
    private auth: AuthService,
    private router: Router
  ) {
    this.tokensRenokeUrl = `${environment.apiUrl}/api/tokens/revoke`;
  }

  async logout() {
    await this.http.delete(this.tokensRenokeUrl, { withCredentials: true })
      .toPromise();
    this.auth.limparAccessToken();
    this.router.navigate(['']);
  }

}
