import { BuilderHttp } from '../../../seguranca/builder-http';
import { HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import 'rxjs/add/operator/toPromise';
import { AgendamentoConsultaFiltro, ConsultaProfissionalAgendamentoFiltro, ProfissionalFiltro, AgendamentoConsulta, RelatorioConsultaValidadaFiltro } from 'src/app/core/models';
import { Observable } from 'rxjs';


@Injectable()
export class ConsultaService {

  statusConsultas = [
    { 'Marcada': 'MARCADA' },
    { 'Aguardando': 'AGUARDANDO' },
    { 'Cancelada': 'CANCELADA' },
    { 'Realizada': 'REALIZADA' },
    { 'Não compareceu': 'NAO_COMPARECEU' },
    { 'Cancelado por Mudança de Horário': 'CANCELADO_MUDOU_HORARIO' }
  ];

  tiposProfissionais = [
    { 'Interno': 'INTERNO' },
    { 'Credenciado': 'CREDENCIADO' }
  ];

  consultaUrlAPI = `${environment.apiUrl}/api/consultas`;
  carteiraUrlAPI = `${environment.apiUrl}/api/carteiras`;
  profissionalUrlAPI = `${environment.apiUrl}/api/profissionais`;

  constructor(
    private httpBuilder: BuilderHttp
  ) { }

  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.consultaUrlAPI)
      .toPromise();
  }

  getPesquisar(filtro: AgendamentoConsultaFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.dataConsulta) {
      params = params.append('dataConsulta', moment(filtro.dataConsulta).format('YYYY-MM-DD'));
    }
    if (filtro.carteiraNumero) {
      params = params.append('carteiraNumero', filtro.carteiraNumero);
    }
    if (filtro.nomeCarteira) {
      params = params.append('nomeCarteira', filtro.nomeCarteira);
    }
    if (filtro.statusConsulta) {
      params = params.append('statusConsulta', filtro.statusConsulta);
    }

    params = params.append('tipoAtendimento', filtro.tipoAtendimento);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/page?`,
      { params })
      .toPromise()
      .then(response => {
        const consultas = response.content;

        for (let i = 0; i < consultas.length; i++) {

          this.statusConsultas.forEach(function (objeto) {
            for (var chave in objeto)
              if (objeto[chave] === consultas[i].statusConsulta) {
                consultas[i].statusConsulta = chave;
              }
          });
        }

        const resultado = {
          consultas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  buscarPorId(id: string): Promise<any> {
    return this.httpBuilder.get<AgendamentoConsulta>(`${this.consultaUrlAPI}/autorizacao/${id}`)
      .toPromise();
  }

  confirmarCancelamento(dados: any): Promise<any> {

    let params = new HttpParams();

    params = params.append('justificativaCancelamento', dados.justificativaCancelamento);
    params = params.append('cancelamentoId', dados.id);

    return this.httpBuilder.get(`${this.consultaUrlAPI}/cancela`, { params })
      .toPromise()
      .then(() => null);
  }

  getCarregarDisponiveis(filtroConsulta: AgendamentoConsultaFiltro, filtro: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams();

    params = params.append('especialidade', filtro.especialidade);
    params = params.append('tipoProfissional', filtro.tipoProfissional);
    params = params.append('carteiraNumero', filtroConsulta.carteiraId);

    if (filtro.tipoProfissional == "1") {

      params = params.append('dataConsulta', moment(filtroConsulta.dataConsulta).format('YYYY-MM-DD'));
    }
    else {

      params = params.append('dataConsulta', moment(new Date()).format('YYYY-MM-DD'));
    }
    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/vagasDisponiveis?`, { params })
      .toPromise()
  }

  buscarCarteira(carteiraNumero: string): Observable<any> {
    return this.httpBuilder.get<any>(`${this.carteiraUrlAPI}/carteira/${carteiraNumero}`)
  }

  buscarCarteiraResumo(carteiraNumero: string): Observable<any> {
    return this.httpBuilder.get<any>(`${this.carteiraUrlAPI}/carteira/resumo/${carteiraNumero}`)
  }

  buscarPorAutorizacao(id: any): Promise<any> {
    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/autorizacaoNumero/${id}`)
      .toPromise();
  }

  async buscarPorAutorizacaoEmLote(selectedAno: string, selectedMes: string, selectedinstituicaoSaude: string) {

    let params = new HttpParams()
                            .append('ano', selectedAno)
                            .append('mes', selectedMes)
                            .append('instituicaoSaude', selectedinstituicaoSaude);

      return await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/autorizacaoNumeroEmLote`, {params})
      .toPromise()
      .then((response) => response)

  }

  validarConsulta(codigo: string): Promise<void> {

    return this.httpBuilder.put(`${this.consultaUrlAPI}/${codigo}/validar`, null)
      .toPromise()
      .then(() => null);
  }

  confirmarMarcacao(agendamento: AgendamentoConsulta, filtro: AgendamentoConsultaFiltro): Promise<any> {

    if (agendamento.tipoAtendimento == "CREDENCIADO") {
      agendamento.tipoAtendimento = "EXTERNO";
      agendamento.dataConsulta = moment(new Date()).format('YYYY-MM-DD');
    }

    return this.httpBuilder
      .post<AgendamentoConsulta>(`${this.consultaUrlAPI}/agendar`, agendamento)
      .toPromise()
      .then((response) => response)
  }

  getPesquisarProfissional(filtroProfissional: ConsultaProfissionalAgendamentoFiltro, caminhoProfissional: String): Promise<any> {

    if (caminhoProfissional == "/consultaMedica/novo") {
      filtroProfissional.tipoProfissional = "INTERNO";
    } else if (caminhoProfissional == "/consultaMedicaCredenciado/novo") {
      filtroProfissional.tipoProfissional = "CREDENCIADO";
    }

    let params = new HttpParams({
      fromObject: {
        page: filtroProfissional.page.toString(),
        size: filtroProfissional.linesPerPage.toString()
      }
    });

    if (filtroProfissional.profissional) { params = params.append('profissional', filtroProfissional.profissional) }
    if (filtroProfissional.especialidade) { params = params.append('especialidade', filtroProfissional.especialidade) }
    if (filtroProfissional.instituicaoSaude) { params = params.append('instituicaoSaude', filtroProfissional.instituicaoSaude) }

    params = params.append('tipoProfissional', filtroProfissional.tipoProfissional);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/page/buscarDisponibilidade?`,
      { params })
      .toPromise()
      .then(response => {
        const consultasProfissional = response.content;
        const resultado = {
          consultasProfissional,
          total: response.totalElements
        };

        return resultado;
      });
  }

  verificaAgendamentoExistente(filtroConsulta: AgendamentoConsultaFiltro, filtroProfissional: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams();

    params = params.append('especialidade', filtroProfissional.especialidade);
    params = params.append('carteiraNumero', filtroConsulta.carteiraId);
    params = params.append('tipoProfissional', filtroProfissional.tipoProfissional);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/verificaAgendamento?`,
      { params })
      .toPromise()
      .then(response => response);
  }

  getPesquisarParaAgendamento(filtro: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.nomeProfissional) {
      params = params.append('nomeProfissional', filtro.nomeProfissional);
    }
    if (filtro.especialidade) {
      params = params.append('especialidade', filtro.especialidade);
    }
    if (filtro.instituicaoSaude) {
      params = params.append('instituicaoSaude', filtro.instituicaoSaude);
    }
    if (filtro.tipoProfissional) {
      params = params.append('tipoProfissional', filtro.tipoProfissional);
    }
    if (filtro.dataConsulta != '') {
      params = params.append('dataConsulta', moment(filtro.dataConsulta).format('YYYY-MM-DD'));
    } else {
      params = params.append('dataConsulta', moment(Date()).format('YYYY-MM-DD'));
    }

    return this.httpBuilder.get<any>(`${this.profissionalUrlAPI}/page/buscaProfissionalConsultaResumo?`,
      { params })
      .toPromise()
      .then(response => {
        let profissionais = response.content;
        for (let i = 0; i < profissionais.length; i++) {

          this.tiposProfissionais.forEach(function (objeto) {
            for (var chave in objeto)
              if (objeto[chave] === profissionais[i].tiposProfissionais) {
                profissionais[i].tiposProfissionais = chave;
              }
          });
        }
        if (filtro.tipoProfissional == "1") {
          var brasil = moment().locale('pt-br')
          let dia = brasil.localeData().weekdays(moment(filtro.dataConsulta));
          dia = dia.substring(0, dia.length - 6).toUpperCase()
          let horario = [];
          if (dia == 'TERÇA') {
            dia = 'TERCA'
          }
          if (dia == 'SÁBADO') {
            dia = 'SABADO'
          }

          profissionais.map((obj) => {
            obj.horariosProfissional = obj.horariosProfissional.filter((childObj) => {
              if (childObj.diaDaSemana === dia) {
                return true
              }
              return false
            })
            return obj
          })
        }
        const resultado = {
          profissionais,
          total: response.totalElements
        };
        return resultado;

      });
  }

  calcularIdade(d_nascimento: string) {

    d_nascimento = moment(d_nascimento).format('YYYY-MM-DD');
    let hoje = moment(new Date()).format('YYYY-MM-DD');

    let idade = moment(hoje).diff(d_nascimento, 'years', false);

    return idade
  }

  getBuscarAgendadosDia(filtro: AgendamentoConsultaFiltro): Promise<any> {

    let params = new HttpParams();

    if (filtro.dataConsulta) {
      params = params.append('dataConsulta', moment(filtro.dataConsulta).format('YYYY-MM-DD'));
    }
    if (filtro.nomeCarteira) {
      params = params.append('nomeProfissional', filtro.nomeProfissional);
    }

    params = params.append('tipoAtendimento', filtro.tipoAtendimento);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/relatorioAgendamentoDia?`,
      { params })
      .toPromise()
      .then(response => { return response;
      });
  }

  imprimirAgendamentoDia(dados: any) {

    let params = new HttpParams();

    params = params.append('dataAgendada', moment(dados.dataConsulta).format('YYYY-MM-DD'));
    params = params.append('profissionalId', dados.profissionalId);
    params = params.append('turnoProfissional', dados.turnoProfissional);
    params = params.append('horario', dados.turno + " - " + dados.horaInicio + " HS");

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/imprimeRelatorioAgendamentoConsultaDia`,
      { responseType: 'blob', params })
      .toPromise();
  }

  getCarregarConsultasValiadadasMensal(filtroConsulta: RelatorioConsultaValidadaFiltro): Promise<any> {

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/ConsultasValiadadasMensal`)
      .toPromise()
  }

  getPesquisaConsultasValiadadasMensal(filtro: RelatorioConsultaValidadaFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.razaoSocial) {
      params = params.append('razaoSocial', filtro.razaoSocial);
    }
    if (filtro.instituicaoId) {
      params = params.append('instituicaoId', filtro.instituicaoId);
    }
    if (filtro.ano) {
      params = params.append('ano', filtro.ano);
    }
    if (filtro.mes) {
      params = params.append('mes', filtro.mes);
    }

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/consultasValidadas/page?`,
      { params })
      .toPromise()
      .then(response => {
        const consultas = response.content
        const resultado = {
          consultas,
          total: response.totalElements
        }

        return resultado;
      })
  }

  gerarRelatorioConsultaCredenciado(dados) {

    let params = new HttpParams();

    params = params.append('ano', dados.ano);
    params = params.append('mes', dados.mes);
    params = params.append('instituicaoId', dados.instituicaoId);
    params = params.append('razaoSocial', dados.razaoSocial);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/gerarRelatorioConsultaCredenciado`,
      { responseType: 'blob', params })
      .toPromise();

  }

  async getCarregaAno() {

    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/listaAnos`)
      .toPromise();
    return response;
  }

  async getCarregaMeses() {

    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/listaMeses`)
      .toPromise();
    return response;
  }

  async valorTotalValidadoMesAtual() {
    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/valorTotalValidadoMesAtual`)
      .toPromise();
    return response;
  }

  async valorTotalValidadoMesAnterior() {
    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/valorTotalValidadoMesAnterior`)
      .toPromise();
    return response;
  }

  async qtdTotalValidadas() {
    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/qtdTotalValidadas`)
      .toPromise();
    return response;
  }

  async qtdTotalValidadasAnterior() {
    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/qtdTotalValidadasAnterior`)
      .toPromise();
    return response;
  }

  async valorTotalLiberadoMesAtual() {
    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/valorTotalLiberadoMesAtual`)
      .toPromise();
    return response;
  }

  async valorTotalLiberadoMesAnterior() {
    const response = await this.httpBuilder.get<any>(`${this.consultaUrlAPI}/valorTotalLiberadoMesAnterior`)
      .toPromise();
    return response;
  }

  gerarRelatorioConsultasAutorizadas(
    selectedAno: string,
    selectedMes: string,
    selectedTipoRelatorio: string,
    selectedEspecialidade: string) {

    let params = new HttpParams();
    params = params.append('ano', selectedAno);
    params = params.append('mes', selectedMes);

    if (selectedEspecialidade == undefined || selectedEspecialidade == 'Todas' || selectedEspecialidade == '') {

      if (selectedTipoRelatorio == 'sintetico') {
        return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/relatorioConsultasAutorizadaSintetico`,
          { responseType: 'blob', params })
          .toPromise();
      } else {
        return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/relatorioConsultasAutorizadaAnalitico`,
          { responseType: 'blob', params })
          .toPromise();
      }
    } else {

      params = params.append('especialidade_id', selectedEspecialidade);

      if (selectedTipoRelatorio == 'sintetico') {
        return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/relatorioConsultasAutorizadaSinteticoEspecialidade`,
          { responseType: 'blob', params })
          .toPromise();
      } else {
        return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/relatorioConsultasAutorizadaAnaliticoEspecialidade`,
          { responseType: 'blob', params })
          .toPromise();
      }

    }

  }

  imprimirAutorizacaoPDF(id: string, tipo: string, horaInicio: string) {

    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('tipoConsulta', tipo);
    params = params.append('horaInicio', horaInicio);

        return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/imprimirGuiaConsultaPDF`,
          { responseType: 'blob', params})
          .toPromise();

  }

  reverterConsulta(entity: AgendamentoConsulta): Observable<AgendamentoConsulta> {
    return this.httpBuilder.get<AgendamentoConsulta>(`${this.consultaUrlAPI}/reverterConsulta/${entity.id}`).pipe()
  }

  cancelarConsulta(entity: AgendamentoConsulta): Observable<AgendamentoConsulta> {
    return this.httpBuilder.get<AgendamentoConsulta>(`${this.consultaUrlAPI}/cancelarConsulta/${entity.id}`).pipe()
  }

  gerarRelatorioConsultasValidadasSus(selectedAno: string) {

    let params = new HttpParams();
    params = params.append('ano', selectedAno);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/gerarRelatorioConsultaCredenciadoSus`,
      { responseType: 'blob', params })
      .toPromise();
  }

  aprovarEmLote(selectedAgentamentoConsulta: AgendamentoConsulta[]) {

    selectedAgentamentoConsulta.forEach(element => {
      element.statusConsulta = '1';
      element.tipoAtendimento = 'EXTERNO';
    });

    return this.httpBuilder
      .post<AgendamentoConsulta[]>(`${this.consultaUrlAPI}/aprovarEmLote`, selectedAgentamentoConsulta)
      .toPromise()
      .then((response) => response)

  }

  gerarRelatorioConsultasAutorizadasTotais(selectedAno: string) {

    let params = new HttpParams();
    params = params.append('ano', selectedAno);

    return this.httpBuilder.get<any>(`${this.consultaUrlAPI}/relatorioConsultasAutorizadasGeral`,
      { responseType: 'blob', params })
      .toPromise();
  }

}
