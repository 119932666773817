import { UsuariosTotais } from './../core/models';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import * as Prism from 'prismjs';
import { AuthService } from '../seguranca/auth.service';
import { ConsultaService } from '../pages/consulta/consulta/consulta.service';
import { ExameService } from '../pages/exame/exame/exame.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  valorTotalLiberadoMesAtual: string
  valorTotalLiberadoMesAnterior: string

  valorTotalValidadoMesAtual: string
  valorTotalValidadoMesAnterior: string

  qtdTotalValidadas: string
  qtdTotalValidadasAnterior: string

  valorTotalLiberadoExameMesAtual: string
  valorTotalLiberadoExameMesAnterior: string

  valorTotalValidadoExameMesAtual: string
  valorTotalValidadoExameMesAnterior: string

  relatorioLiberados : []

  examesRelatorio: []
  consultaRelatorio: []
  totalUsuarios: UsuariosTotais

  cols = [
    { head: 'Razão Social' },
    { head: 'Atual' },
    { head: 'Anterior' }
  ]

  constructor(
    private auth: AuthService,
    private consultaService: ConsultaService,
    private exameService: ExameService
  ) { }

  ngOnInit() {
    this.getValorTotalValidadoMesAtual()
    this.getValorTotalValidadoMesAnterior()

    this.getValorTotalLiberadoMesAtual()
    this.getValorTotalLiberadoMesAnterior()

    this.getQtdTotalValidadas()
    this.getQtdTotalValidadasAnterior()

    this.getValorTotalLiberadoExameMesAnterior()
    this.getValorTotalLiberadoExameMesAtual()

    this.getValorTotalValidadoExameMesAnterior()
    this.getValorTotalValidadoExameMesAtual()

    this.getExamesRelatorio()
    this.getConsultasRelatorio()
    this.getUsuariosTotais()
  }

  /**
   * @method ngAfterViewInit
   */
  ngAfterViewInit() {
    Prism.highlightAll();
  }

  getPermissaoCarteiraServidor() {

    if (this.auth.temPermissao('ROLE_ACESSA_CARTEIRA_TIPO_SERVIDOR_PUBLICO')) {
      return true;
    } else {
      return false;
    }

  }

  getPermissaoCarteira() {
    if (this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_PESQUISA_CARTEIRA')) {
      return true;
    } else {
      return false;
    }
  }

  getPermissaoConsulta() {
    if (this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_INTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_EXTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_FISIOTERAPIA')) {
      return true;
    } else {
      return false;
    }
  }

  getPermissaoConsultaNovo() {
    if (this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_INTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_EXTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_PESQUISA_CONSULTA_FISIOTERAPIA')) {
      return true;
    } else {
      return false;
    }
  }

  getPermissaoExame() {
    if (this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_EXTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_EXTERNA_LABORATORIO')) {
      return true;
    } else {
      return false;
    }
  }

  getPermissaoExameNovo() {
    if (this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_EXTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_EXTERNA_LABORATORIO')) {
      return true;
    } else {
      return false;
    }
  }

  getPermissaoAdministrador() {
    if (this.auth.temPermissao('ROLE_ADMINISTRADOR')) {
      return true;
    } else {
      return false;
    }
  }

  getValorTotalLiberadoMesAtual() {
    this.consultaService.valorTotalLiberadoMesAtual()
      .then(resultado => {
        this.valorTotalLiberadoMesAtual = resultado
      })
  }

  getValorTotalLiberadoMesAnterior() {
    this.consultaService.valorTotalLiberadoMesAnterior()
      .then(resultado => {
        this.valorTotalLiberadoMesAnterior = resultado
      })
  }

  getValorTotalValidadoMesAtual() {
    this.consultaService.valorTotalValidadoMesAtual()
      .then(resultado => {
        this.valorTotalValidadoMesAtual = resultado
      })
  }

  getValorTotalValidadoMesAnterior() {
    this.consultaService.valorTotalValidadoMesAnterior()
      .then(resultado => {
        this.valorTotalValidadoMesAnterior = resultado
      })
  }

  getQtdTotalValidadas() {
    this.consultaService.qtdTotalValidadas()
      .then(resultado => {
        this.qtdTotalValidadas = resultado
      })
  }

   getQtdTotalValidadasAnterior() {
    this.consultaService.qtdTotalValidadasAnterior()
      .then(resultado => {
        this.qtdTotalValidadasAnterior = resultado
      })
  }

  getValorTotalLiberadoExameMesAtual() {
    this.exameService.valorTotalLiberadoxameMesAtual()
      .then(resultado => {
        this.valorTotalLiberadoExameMesAtual = resultado
      })
  }

  getValorTotalLiberadoExameMesAnterior() {
    this.exameService.valorTotalLiberadoExameMesAnterior()
      .then(resultado => {
        this.valorTotalLiberadoExameMesAnterior = resultado
      })
  }
  getValorTotalValidadoExameMesAtual() {
    this.exameService.valorTotalValidadoxameMesAtual()
      .then(resultado => {
        this.valorTotalValidadoExameMesAtual = resultado
      })
  }

  getValorTotalValidadoExameMesAnterior() {
    this.exameService.valorTotalValidadoExameMesAnterior()
      .then(resultado => {
        this.valorTotalValidadoExameMesAnterior = resultado
      })
  }

  getExamesRelatorio() {
    this.exameService.getExamesRelatorio()
      .then(resultado => {
        this.examesRelatorio = resultado
      })
  }

  getConsultasRelatorio() {
    this.exameService.getConsultaRelatorio()
      .then(resultado => {
        this.consultaRelatorio = resultado
      })
  }

  getUsuariosTotais() {
    this.exameService.getUsuariosTotais()
      .then(resultado => {

        this.totalUsuarios = resultado

      })
  }

}
