// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,

  ambiente: "prod",

  version: "1.0.0",
  
  contentType: 'application/x-www-form-urlencoded',
  basicAuthorization: 'Basic YW5ndWxhcjpAbmd1bEByMA==',

  /*
  apiUrl: 'http://localhost:6032',
  tokenWhitelistedDomains: [ /localhost:6032/ ],
  tokenBlacklistedRoutes: [ /\/oauth\/token/ ]
  
  */
 /*
  apiUrl: 'http://10.10.1.250:6032',
  tokenWhitelistedDomains: [ /10.10.1.250:6032/ ],
  tokenBlacklistedRoutes: [ /\/oauth\/token/ ]
  
  */
  
  apiUrl: 'https://sigas.iass.pb.gov.br:60931',
  tokenWhitelistedDomains: [new RegExp('sigas.iass.pb.gov.br:60931') ],
  tokenBlacklistedRoutes: [new RegExp('\/oauth\/token')]
 
};