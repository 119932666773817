import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageComponent } from './message/message.component';
import { ObrigatorioComponent } from './message/obrigatorio.component';
import { FormDebugComponent } from './form-debug/form-debug.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MessageComponent, 
    ObrigatorioComponent,
    FormDebugComponent
  ],
  exports: [
    MessageComponent, 
    ObrigatorioComponent,
    FormDebugComponent
  ]})
export class SharedModule { }
