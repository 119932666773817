import { BuilderHttp } from './../../../seguranca/builder-http';
import { HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { Orgao, OrgaoFiltro } from 'src/app/core/models';

@Injectable()
export class OrgaoService {
    
  statusOrgaos = [
    { 'Ativo' : 'ATIVO' },
    { 'Bloqueado' : 'BLOQUEADO' }
  ];
  
  orgaos: Orgao[];
  orgao: Orgao;
  orgaoUrlAPI = `${environment.apiUrl}/api/orgaos`;
  
  constructor(
    private httpBuilder: BuilderHttp
  ) {  }

  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.orgaoUrlAPI)
      .toPromise();
  }

  getPesquisar(filtro: OrgaoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.id) {
      params = params.append('id', filtro.id);
    }

    if (filtro.nomeOrgao) {
      params = params.append('nomeOrgao', filtro.nomeOrgao);
    }

    return this.httpBuilder.get<any>(`${this.orgaoUrlAPI}/page?`,
      { params })
      .toPromise()
      .then(response => {
        const orgaos = response.content;

        for (let i = 0; i < orgaos.length; i++) {

          this.statusOrgaos.forEach(function(objeto) {
            for ( var chave in objeto )
                if (objeto[chave] === orgaos[i].statusOrgao) {
                  orgaos[i].statusOrgao = chave;
                }
          });    
        } 

        const resultado = {
          orgaos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  excluir(id: number): Promise<void> {
    return this.httpBuilder
      .delete(`${this.orgaoUrlAPI}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(orgao: Orgao): Promise<Orgao> {
    return this.httpBuilder
      .post<Orgao>(this.orgaoUrlAPI, orgao)
      .toPromise()
      .then(response => response)
  }

  atualizar(orgao: Orgao): Promise<Orgao> {
    return this.httpBuilder
      .put<Orgao>(`${this.orgaoUrlAPI}/${orgao.id}`, orgao)
      .toPromise()
  }

  buscarPorId(id: number): Promise<Orgao> {
    return this.httpBuilder.get<Orgao>(`${this.orgaoUrlAPI}/${id}`)
      .toPromise();
  }

  mudarStatus(codigo: number, ativo: number): Promise<void> {

    return this.httpBuilder.put(`${this.orgaoUrlAPI}/${codigo}/ativo`, ativo)
      .toPromise()
      .then(() => null);
  }

}
