import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'angular-admin-lte';
import { Usuario } from './seguranca/login/usuario';
import { ToastyConfig } from 'ng2-toasty';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public customLayout: boolean;
  private usuario: Usuario = new Usuario();
  checked = false;
  ambiente: boolean;

  constructor(
    private layoutService: LayoutService,
    private toastyConfig: ToastyConfig
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit() {
    this.layoutService.isCustomLayout.subscribe((value: boolean) => {
      this.customLayout = value;
      this.ambiente = processaAmbiente();
    });
  }
}

function processaAmbiente() {

  let ambienteAtivo = `${environment.ambiente}`;

  if (ambienteAtivo === 'dev') {
    return true;
  }

  return false;

}

