import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './seguranca/login/login.component';

import { AuthGuard } from './seguranca/auth.guard';
import { NaoAutorizadoComponent } from './core/naoAutorizado';
import { PaginaNaoEncontradaComponent } from './core/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { CoreModule } from './core/core.module';
import * as Prism from 'prismjs';
import { RecuperarSenhaComponent } from './seguranca/recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './seguranca/nova-senha/nova-senha.component';

const routes: Routes = [

  {
    path: '', data: { canActivate: [AuthGuard] },
    children: [
      { path: 'usuario', loadChildren: './pages/usuario/usuario.module#UsuarioModule', data: { title: 'Usuario' } },
      { path: 'carteiras', loadChildren: './pages/carteira/carteira/carteira.module#CarteiraModule', data: { title: 'Carteiras' } },
      { path: 'orgao', loadChildren: './pages/general/orgao/orgao.module#OrgaoModule', data: { title: 'Orgão' } },
      { path: 'especialidade', loadChildren: './pages/general/especialidade/especialidade.module#EspecialidadeModule', data: { title: 'Especialidade' } },
      //  { path: 'lotacao', loadChildren: './pages/general/lotacao#LotacaoModule', data: { title: 'Lotação' } },
      { path: 'procedimentoambulatorial', loadChildren: './pages/general/procedimento-ambulatorial/procedimento-ambulatorial.module#ProcedimentoAmbulatorialModule', data: { title: 'Procedimento Ambulatorial' } },
      { path: 'agrupamentoambulatorial', loadChildren: './pages/general/agrupamento-ambulatorial/agrupamento-ambulatorial.module#AgrupamentoAmbulatorialModule', data: { title: 'Agrupamento Ambulatorial' } },
      { path: 'grupoambulatorial', loadChildren: './pages/general/grupo-ambulatorial/grupo-ambulatorial.module#GrupoAmbulatorialModule', data: { title: 'Grupo Ambulatorial' } },
      { path: 'agencia', loadChildren: './pages/general/agencia/agencia.module#AgenciaModule', data: { title: 'Agência de Atendimento' } },
      { path: 'instituicaoSaude', loadChildren: './pages/general/instituicao-saude/instituicao-saude.module#InstituicaoSaudeModule', data: { title: 'Instituição de Saúde' } },
      { path: 'profissional', loadChildren: './pages/general/profissional/profissional.module#ProfissionalModule', data: { title: 'Profissional de Saúde' } },
      { path: 'conselhoClasse', loadChildren: './pages/general/conselho/conselho.module#ConselhoModule', data: { title: 'Conselho de Classe' } },
      { path: 'consultaMedica', loadChildren: './pages/consulta/consulta/consulta.module#ConsultaModule', data: { title: 'Consultas Médicas' } },
      { path: 'exame', loadChildren: './pages/exame/exame/exame.module#ExameModule', data: { title: 'Exames' } },
      // { path: 'alterarSenha', loadChildren: () => MudarsenhaModule, data: { title: 'Alterar Senha' } },
      { path: '', component: LoginComponent, data: { customLayout: true } },
      { path: 'recuperarSenha', component: RecuperarSenhaComponent, data: { customLayout: true } },
      { path: 'novaSenha/:id', component: NovaSenhaComponent, data: { customLayout: true } },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { customLayout: false, title: 'DashBoard' } },
      { path: 'naoAutorizado', component: NaoAutorizadoComponent },
      { path: '', redirectTo: '', pathMatch: 'full', canActivate: [AuthGuard] },
      { path: '**', component: PaginaNaoEncontradaComponent, canActivate: [AuthGuard] }
    ]
  }];

@NgModule({
  imports: [
    CoreModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  /**
 * @method ngAfterViewInit
 */
  ngAfterViewInit() {
    Prism.highlightAll();
  }
}
