import { BuilderHttp } from '../../../seguranca/builder-http';
import { HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import 'rxjs/add/operator/toPromise';

import { AgendamentoExameFiltro, AgendamentoExame, ProfissionalFiltro, RelatorioExameValidadoFiltro, UsuariosTotais } from 'src/app/core/models';

@Injectable()
export class ExameService {

  statusExames = [
    { 'Marcada': 'MARCADA' },
    { 'Realizada': 'REALIZADA' },
    { 'Cancelada': 'CANCELADA' },
    { 'Não compareceu': 'NAO_COMPARECEU' }
  ];

  tiposProfissionais = [
    { 'Interno': 'INTERNO' },
    { 'Credenciado': 'CREDENCIADO' }
  ];

  profissionalUrlAPI = `${environment.apiUrl}/api/profissionais`;
  exameUrlAPI = `${environment.apiUrl}/api/exames`;
  carteiraUrlAPI = `${environment.apiUrl}/api/carteiras`;
  procedimentosUrlAPI = `${environment.apiUrl}/api/procedimentosambulatoriais`;
  transferido: any;

  private obterDadoServiceBehaviorSubject = new BehaviorSubject<any>(this.transferido);

  constructor(
    private httpBuilder: BuilderHttp
  ) { }

  enviarDados(informacao: any) {
    return this.obterDadoServiceBehaviorSubject.next(informacao);
  }

  obterDados() {
    return this.obterDadoServiceBehaviorSubject;
  }

  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.exameUrlAPI)
      .toPromise();
  }

  getPesquisar(filtro: AgendamentoExameFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.dataCadastro) {
      params = params.append('dataCadastro', moment(filtro.dataCadastro).format('YYYY-MM-DD'));
    }
    if (filtro.carteiraNumero) {
      params = params.append('carteiraNumero', filtro.carteiraNumero);
    }
    if (filtro.nomeCarteira) {
      params = params.append('nomeCarteira', filtro.nomeCarteira);
    }
    if (filtro.statusExame) {
      params = params.append('statusExame', filtro.statusExame);
    }

    params = params.append('tipoAtendimento', filtro.tipoAtendimento);

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/page?`,
      { params })
      .toPromise()
      .then(response => {
        const exames = response.content;

        for (let i = 0; i < exames.length; i++) {

          this.statusExames.forEach(function (objeto) {
            for (var chave in objeto)
              if (objeto[chave] === exames[i].statusExame) {
                exames[i].statusExame = chave;
              }
          });
        }

        const resultado = {
          exames,
          total: response.totalElements
        };

        return resultado;
      });
  }

  buscarPorId(id: string): Promise<any> {
    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/autorizacao/${id}`)
      .toPromise();
  }

  buscarPorAutorizacao(id: any): Promise<any> {
    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/autorizacaoNumero/${id}`)
      .toPromise();
  }

  confirmarCancelamento(codigo: number, cancelamento: number): Promise<void> {

    return this.httpBuilder.put(`${this.exameUrlAPI}/${codigo}/cancela`, cancelamento)
      .toPromise()
      .then(() => null);
  }

  validarExame(codigo: string): Promise<void> {

    return this.httpBuilder.put(`${this.exameUrlAPI}/${codigo}/validar`, 2)
      .toPromise()
      .then(() => null);
  }

  getCarregarDisponiveis(filtroExame: AgendamentoExameFiltro, filtro: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams();

    params = params.append('especialidade', filtro.especialidade);
    params = params.append('tipoProfissional', filtro.tipoProfissional);
    params = params.append('carteiraNumero', filtroExame.carteiraId);

    if (filtro.tipoProfissional == "1") {

      params = params.append('dataExame', moment(filtroExame.dataCadastro).format('YYYY-MM-DD'));
    }
    else {

      params = params.append('dataExame', moment(new Date()).format('YYYY-MM-DD'));
    }
    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/vagasDisponiveis?`, { params })
      .toPromise()
  }

  buscarCarteira(carteiraNumero: string): Observable<any> {
    return this.httpBuilder.get<any>(`${this.carteiraUrlAPI}/carteira/${carteiraNumero}`)
  }

  buscarCarteiraResumo(carteiraNumero: string): Observable<any> {
    return this.httpBuilder.get<any>(`${this.carteiraUrlAPI}/carteira/resumo/${carteiraNumero}`)
  }

  confirmarMarcacao(agendamento: AgendamentoExame): Promise<any> {

    if (agendamento.tipoAtendimento == "2") {
      agendamento.tipoAtendimento = "EXTERNO";
      agendamento.dataCadastro = moment(new Date()).format('YYYY-MM-DD');
    } else if (agendamento.tipoAtendimento == "1") {
      agendamento.tipoAtendimento = "INTERNO";
      agendamento.dataCadastro = moment(new Date()).format('YYYY-MM-DD');
    }

    let X = []
    for (let index = 0; index < agendamento.examesBeneficiario.length; index++) {
      const element = agendamento.examesBeneficiario[index].id;
      X.push(element)
    }

    agendamento.examesBeneficiario = X;

    return this.httpBuilder
      .post<any>(`${this.exameUrlAPI}/agendar`, agendamento)
      .toPromise()
      .then((response) => response)
  }

  verificaAgendamentoExistente(filtroExame: AgendamentoExameFiltro, filtroProfissional: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams();

    params = params.append('especialidade', filtroProfissional.especialidade);
    params = params.append('carteiraNumero', filtroExame.carteiraId);
    params = params.append('tipoProfissional', filtroProfissional.tipoProfissional);

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/verificaAgendamento?`,
      { params })
      .toPromise()
      .then(response => response);
  }

  getPesquisarParaAgendamento(filtro: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.nomeProfissional) {
      params = params.append('nomeProfissional', filtro.nomeProfissional);
    }
    if (filtro.especialidade) {
      params = params.append('especialidade', filtro.especialidade);
    }
    if (filtro.instituicaoSaude) {
      params = params.append('instituicaoSaude', filtro.instituicaoSaude);
    }
    if (filtro.tipoProfissional) {
      params = params.append('tipoProfissional', filtro.tipoProfissional);
    }

    return this.httpBuilder.get<any>(`${this.profissionalUrlAPI}/page/buscaProfissionalExameResumo?`,
      { params })
      .toPromise()
      .then(response => {
        const profissionais = response.content;

        for (let i = 0; i < profissionais.length; i++) {

          this.tiposProfissionais.forEach(function (objeto) {
            for (var chave in objeto)
              if (objeto[chave] === profissionais[i].tiposProfissionais) {
                profissionais[i].tiposProfissionais = chave;
              }
          });
        }
        const resultado = {
          profissionais,
          total: response.totalElements
        };
        return resultado;
      });
  }

  carregarExamesPorEspecialidade(especialidade: string): Promise<any> {

    let params = new HttpParams();

    params = params.append('especialidade', especialidade);

    return this.httpBuilder.get<any>(`${this.procedimentosUrlAPI}/buscaProcedimentosPorEspecialidade?`,
      { params })
      .toPromise()
      .then(response => response);
  }

  calcularIdade(d_nascimento: string) {

    d_nascimento = moment(d_nascimento).format('YYYY-MM-DD');
    let hoje = moment(new Date()).format('YYYY-MM-DD');

    let idade = moment(hoje).diff(d_nascimento, 'years', false);

    return idade
  }

  getCarregarExamesValiadadosMensal(filtroExame: RelatorioExameValidadoFiltro): Promise<any> {

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/ExamesValiadadosMensal`)
      .toPromise()
  }

  getPesquisaExamesValiadadosMensal(filtro: RelatorioExameValidadoFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.razaoSocial) {
      params = params.append('razaoSocial', filtro.razaoSocial);
    }
    if (filtro.ano) {
      params = params.append('ano', filtro.ano);
    }
    if (filtro.mes) {
      params = params.append('mes', filtro.mes);
    }

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/examesValidados/page?`,
      { params })
      .toPromise()
      .then(response => {
        const exames = response.content
        const resultado = {
          exames,
          total: response.totalElements
        }

        return resultado;
      })
  }

  gerarRelatorioExameCredenciado(dados: RelatorioExameValidadoFiltro) {

    let params = new HttpParams();

    params = params.append('ano', dados.ano);
    params = params.append('mes', dados.mes);
    params = params.append('instituicaoId', dados.instituicaoId);
    params = params.append('razaoSocial', dados.razaoSocial);

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/gerarRelatorioExameCredenciado`,
      { responseType: 'blob', params })
      .toPromise();

  }

  async getCarregaAno() {

    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/listaAnos`)
      .toPromise();
    return response;
  }

  async getCarregaMeses() {

    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/listaMeses`)
      .toPromise();
    return response;
  }

  async valorTotalLiberadoxameMesAtual() {
    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/valorTotalLiberadoExameMesAtual`)
      .toPromise();
    return response;
  }

  async valorTotalLiberadoExameMesAnterior() {
    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/valorTotalLiberadoExameMesAnterior`)
      .toPromise();
    return response;
  }

  async valorTotalValidadoxameMesAtual() {
    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/valorTotalValidadoExameMesAtual`)
      .toPromise();
    return response;
  }

  async valorTotalValidadoExameMesAnterior() {
    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/valorTotalValidadoExameMesAnterior`)
      .toPromise();
    return response;
  }

  gerarRelatorioExamesAutorizados(
    selectedAno: string,
    selectedMes: string,
    selectedTipoRelatorio: string,
    selectedEspecialidade: string) {

    let params = new HttpParams();
    params = params.append('ano', selectedAno);
    params = params.append('mes', selectedMes);

    // if (selectedEspecialidade == undefined || selectedEspecialidade == 'Todas' || selectedEspecialidade == '') {

    if (selectedTipoRelatorio == 'sintetico') {
      return this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorioExamesAutorizadosSintetico`,
        { responseType: 'blob', params })
        .toPromise();
    } else {
      return this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorioExamesAutorizadosAnalitico`,
        { responseType: 'blob', params })
        .toPromise();
    }
    // } else {

    //   params = params.append('especialidade_id', selectedEspecialidade);

    //   if (selectedTipoRelatorio == 'sintetico') {
    //     return this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorioConsultasAutorizadaSinteticoEspecialidade`,
    //       { responseType: 'blob', params })
    //       .toPromise();
    //   } else {
    //     return this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorioConsultasAutorizadaAnaliticoEspecialidade`,
    //       { responseType: 'blob', params })
    //       .toPromise();
    //   }

    // }

  }

  imprimirAutorizacaoPDF(id: string) {

    let params = new HttpParams();
    params = params.append('id', id);

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/imprimirGuiaExamePDF`,
      { responseType: 'blob', params })
      .toPromise();

  }

  async getExamesRelatorio() {
    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorio-exames-autorizados`)
      .toPromise();
    return response;
  }

  async getConsultaRelatorio() {
    const response = await this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorio-consultas-autorizadas`)
      .toPromise();
    return response;
  }

  async getUsuariosTotais() {
    const response = await this.httpBuilder.get<UsuariosTotais>(`${this.carteiraUrlAPI}/relatorio-usuariosTotais`)
      .toPromise();
    return response;
  }

  reverterExame(entity: AgendamentoExame): Observable<AgendamentoExame> {
    return this.httpBuilder.get<AgendamentoExame>(`${this.exameUrlAPI}/reverterExame/${entity.id}`).pipe()
  }

  cancelarExame(entity: AgendamentoExame): Observable<AgendamentoExame> {
    return this.httpBuilder.get<AgendamentoExame>(`${this.exameUrlAPI}/cancelarExame/${entity.id}`).pipe()
  }

  gerarRelatorioExamesValidadosSus(selectedAno: string) {

    let params = new HttpParams();
    params = params.append('ano', selectedAno);

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/gerarRelatorioExamesCredenciadoSus`,
      { responseType: 'blob', params })
      .toPromise();
  }

  aprovarEmLote(selectedAgentamentoExame: AgendamentoExame[]) {

    selectedAgentamentoExame.forEach(element => {
      element.statusExame = '1';
      element.tipoAtendimento = 'EXTERNO';
    });

    return this.httpBuilder
      .post<AgendamentoExame[]>(`${this.exameUrlAPI}/aprovarEmLote`, selectedAgentamentoExame)
      .toPromise()
      .then((response) => response)

  }

  async buscarPorAutorizacaoEmLote(selectedAno: string, selectedMes: string, selectedinstituicaoSaude: string) {

    let params = new HttpParams()
      .append('ano', selectedAno)
      .append('mes', selectedMes)
      .append('instituicaoSaude', selectedinstituicaoSaude);

    return await this.httpBuilder.get<any>(`${this.exameUrlAPI}/autorizacaoNumeroEmLote`, { params })
      .toPromise()
      .then((response) => response)

  }

  gerarRelatorioExamesAutorizadosTotais(selectedAno: string) {

    let params = new HttpParams();
    params = params.append('ano', selectedAno);

    return this.httpBuilder.get<any>(`${this.exameUrlAPI}/relatorioExamesAutorizadosGeral`,
      { responseType: 'blob', params })
      .toPromise();
  }

}
