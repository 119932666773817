import { ErrorHandlerService } from './error-handler.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BoxModule, TabsModule } from 'angular-admin-lte';

import { MessageService } from 'primeng/components/common/messageservice';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ButtonModule } from 'primeng/button';
import { GrowlModule } from 'primeng/growl';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { RelatoriosService } from '../pages/relatorios/relatorios.service';

import { HeaderInnerComponent } from './header-inner/header-inner.component';
import { SidebarLeftInnerComponent } from './sidebar-left-inner/sidebar-left-inner.component';
import { SidebarRightInnerComponent } from './sidebar-right-inner/sidebar-right-inner.component';
import { CidadeService } from '../pages/general/cidade/cidade.service';
import { SetorService } from '../pages/general/setor/setor.service';
import { OrgaoService } from '../pages/general/orgao/orgao.service';
import { UfService } from '../pages/general/uf/uf.service';
import { AuthService } from '../seguranca/auth.service';
import { BuilderHttp } from '../seguranca/builder-http';
import { AuthGuard } from '../seguranca/auth.guard';
import { LogoutService } from '../seguranca/logout.service';
import { NaoAutorizadoComponent } from './naoAutorizado';
import { PaginaNaoEncontradaComponent } from './pagina-nao-encontrada/pagina-nao-encontrada.component';
import { ConselhoService } from '../pages/general/conselho/conselho.service';
import { EspecialidadeService } from '../pages/general/especialidade/especialidade.service';
import { InstituicaoSaudeService } from '../pages/general/instituicao-saude/instituicao-saude.service';
import { AgenciaService } from '../pages/general/agencia/agencia.service';
import { ConsultaService } from '../pages/consulta/consulta/consulta.service';
import { ExameService } from '../pages/exame/exame/exame.service';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TabsModule,
    BoxModule,
    RouterModule,
    ButtonModule,
    GrowlModule,
    TableModule
  ],
  declarations: [
    HeaderInnerComponent,
    SidebarLeftInnerComponent,
    SidebarRightInnerComponent,
    PaginaNaoEncontradaComponent,
    NaoAutorizadoComponent],

  exports: [
    BoxModule,
    TabsModule,
    TableModule,
    HeaderInnerComponent,
    SidebarLeftInnerComponent,
    SidebarRightInnerComponent,
    NaoAutorizadoComponent,
    PaginaNaoEncontradaComponent,
    GrowlModule
  ],
  providers: [
    JwtHelperService,
    AuthService,
    AuthGuard,
    ErrorHandlerService,
    BuilderHttp,
    MessageService,
    LogoutService,
    ConfirmationService,
    SetorService,
    OrgaoService,
    AgenciaService,
    UfService,
    ConselhoService,
    EspecialidadeService,
    InstituicaoSaudeService,
    CidadeService,
    RelatoriosService,
    ConsultaService,
    ExameService
  ]
})
export class CoreModule { }
