import { BuilderHttp } from './../builder-http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ButtonModule } from 'primeng/components/button/button';
import { InputTextModule } from 'primeng/components/inputtext/inputtext';
import { MessageService } from 'primeng/components/common/messageservice';

import { ErrorHandlerService } from './../../core/error-handler.service';
import { AuthService } from '../auth.service';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { AuthGuard } from '../auth.guard';
import { LogoutService } from '../logout.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogModule } from 'primeng/dialog';


export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    LoginRoutingModule,
    InputTextModule,
    ButtonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DialogModule,
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
  providers: [
    AuthService,
    ErrorHandlerService,
    AuthGuard,
    MessageService,
    LogoutService,
    BuilderHttp,
  ]
})
export class LoginModule { }
