import { BuilderHttp } from './../../../seguranca/builder-http';
import { Especialidade, EspecialidadeFiltro, ConsultaProfissionalAgendamentoFiltro, ProfissionalFiltro } from './../../../core/models';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { environment } from 'src/environments/environment';

@Injectable()
export class EspecialidadeService {

  generos = [
    { 'Masculino': 'MASCULINO' },
    { 'Feminino': 'FEMININO' },
    { 'Ambos': 'AMBOS' }
  ];

  especialidadeUrlAPI = `${environment.apiUrl}/api/especialidades`;

  constructor(
    private httpBuilder: BuilderHttp
  ) { }

  getPesquisar(filtro: EspecialidadeFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.linesPerPage.toString()
      }
    });

    if (filtro.id) {
      params = params.append('id', filtro.id);
    }

    if (filtro.nomeEspecialidade) {
      params = params.append('nomeEspecialidade', filtro.nomeEspecialidade);
    }

    return this.httpBuilder.get<any>(`${this.especialidadeUrlAPI}/page?`,
      { params })
      .toPromise()
      .then(response => {
        const especialidades = response.content;

        for (let i = 0; i < especialidades.length; i++) {

          this.generos.forEach(function (objeto) {
            for (var chave in objeto)
              if (objeto[chave] === especialidades[i].genero) {
                especialidades[i].genero = chave;
              }
          });
        }

        const resultado = {
          especialidades,
          total: response.totalElements
        };

        return resultado;
      });
  }

  excluir(id: number): Promise<void> {
    return this.httpBuilder
      .delete(`${this.especialidadeUrlAPI}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(especialidade: Especialidade): Promise<Especialidade> {
    return this.httpBuilder
      .post<Especialidade>(this.especialidadeUrlAPI, especialidade)
      .toPromise()
    // .then(response => response)
  }

  atualizar(especialidade: Especialidade): Promise<Especialidade> {
    return this.httpBuilder
      .put<Especialidade>(`${this.especialidadeUrlAPI}/${especialidade.id}`, especialidade)
      .toPromise()
  }

  buscarPorId(id: number): Promise<Especialidade> {
    return this.httpBuilder.get<Especialidade>(`${this.especialidadeUrlAPI}/${id}`)
      .toPromise();
  }

  listarTodas(): Promise<any> {
    return this.httpBuilder.get(this.especialidadeUrlAPI)
      .toPromise();
  }

  buscaEspecialidadeClassificaco(classificacao): Promise<any> {

    let params = new HttpParams();

    params = params.append('classificacao', classificacao);

    return this.httpBuilder.get<any>(`${this.especialidadeUrlAPI}/buscaEspecialidadeClassificaco?`, { params })
      .toPromise();
  }

  getCarregarVagas(filtro: string): Promise<any> {

    let params = new HttpParams();

    params = params.append('especialidade', filtro);

    return this.httpBuilder.get<any>(`${this.especialidadeUrlAPI}/vagasTotais?`, { params })
      .toPromise()
  }

  listarTodasEspecialidadesAtivas(filtroProfissional: ProfissionalFiltro): Promise<any> {

    let params = new HttpParams();

    if (filtroProfissional.tipoProfissional == "") {
      filtroProfissional.tipoProfissional = "";
      params = params.append('tipoProfissional', filtroProfissional.tipoProfissional);
    } else {
      params = params.append('tipoProfissional', filtroProfissional.tipoProfissional);
    }

    params = params.append('statusProfissional', "1");

    params = params.append('classificacao', filtroProfissional.classificacao);

    return this.httpBuilder.get<any>(`${this.especialidadeUrlAPI}/buscarTodasEspecialidadesAtivas?`,
      { params })
      .toPromise()
      .then(response => response);
  }

  gerarRelatorioEspecialidadesComValor(relatorio: string) {
    return this.httpBuilder.get<any>(`${this.especialidadeUrlAPI}/${relatorio}`,
      { responseType: 'blob'})
      .toPromise();

  }

}
