import { AuthService } from './seguranca/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';

import { adminLteConf } from './admin-lte.conf';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';

import { LayoutModule } from 'angular-admin-lte';

import { ToastyModule } from 'ng2-toasty';

import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { ErrorHandlerService } from './core/error-handler.service';
import { BuilderHttp } from './seguranca/builder-http';
import { AuthGuard } from './seguranca/auth.guard';
import { JwtModuleOptions, JwtModule } from '@auth0/angular-jwt';
import { tokenGetter, LoginModule } from './seguranca/login/login.module';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecuperarSenhaComponent } from './seguranca/recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './seguranca/nova-senha/nova-senha.component';

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: tokenGetter,
    whitelistedDomains: environment.tokenWhitelistedDomains,
    blacklistedRoutes: environment.tokenBlacklistedRoutes
  }
};

@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule.forRoot(adminLteConf),
    JwtModule.forRoot(JWT_Module_Options),
    AppRoutingModule,
    MaterialBarModule,
    LoadingPageModule,
    HttpClientModule,
    FormsModule,
    ToastyModule.forRoot(),
    ConfirmDialogModule,
    SharedModule,
    LoginModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    RecuperarSenhaComponent,
    NovaSenhaComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    ErrorHandlerService, 
    BuilderHttp,
    AuthGuard, 
    MessageService,
    ConfirmationService,
    //{provide: LOCALE_ID, useValue: 'BRL' }
  ],
  exports: [
    ToastModule,
    LoginModule
  ]
})
export class AppModule { }
