import { AuthService } from './../auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../../core/error-handler.service';

import { MessageService } from 'primeng/components/common/messageservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  checked = false;
  jwtPayload: any;
  display: boolean = false;
  versao: string;
  
  constructor(
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private router: Router) { 
      this.versao = `${environment.version}`;
    }

  login(usuario: string, senha: string) {
    this.auth.login(usuario, senha)
      .then(() => {
          this.router.navigate(['/home']);
        })
        .catch( erro => {
          this.errorHandler.handle(erro);
          this.showDialog();
        });
    }

    showDialog() {
        this.display = true;
    }

    esqueciSenha() {
      this.router.navigate(['/recuperarSenha']);
    }
}
