import { Component } from '@angular/core';

@Component({
  selector: 'app-obrigatorio',
  template: `
      <span class="obrigatorio">&nbsp;*</span>
  `,
  styles: [`
    .obrigatorio {
      font: 4;
      color: #ff0000;
      background-color: transparent;
    }
  `]
})
export class ObrigatorioComponent {


}
