import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty/src/toasty.service';
import { Message } from 'primeng/components/common/message';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrls: ['./nova-senha.component.css']
})
export class NovaSenhaComponent implements OnInit {

  checked = false;
  jwtPayload: any;
  display: boolean = false;
  versao: string;
  msgs: Message[] = [];
  userId: string;
  hash: string;

  
  constructor(
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private toasty: ToastyService,
    private routeActive: ActivatedRoute) { 
      this.routeActive.params.subscribe(params => this.userId = params['id']);
      this.versao = `${environment.version}`;
    }

  ngOnInit(): void {
        this.verificaSenha()
  }

  verificaSenha() {
    this.auth.verificaHash(this.userId)
    .then((response) => {    })
    .catch( erro => {
      this.router.navigate(['/']);
      this.errorHandler.handle(erro.error.msg);
      this.toasty.error(erro.error.msg)
    });
  }

  novaSenha(senha: string, novaSenha: string) {

    if (senha === novaSenha) {
     
        this.auth.novaSenha(novaSenha, this.userId)
        .then((response) => {
          this.router.navigate(['/']);
          this.toasty.success(response.msg)
        })
        .catch( erro => {
          this.errorHandler.handle(erro.error.msg);
          this.toasty.error(erro.error.msg)
        });
      } else {
                
      }
    }

    showDialog() {
      this.msgs = [];
      this.msgs.push({severity:'success', summary:'Success Message', detail:'Order submitted'});
    }

    voltar() {
      this.router.navigate(['/']);
    }

    
}
