import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty/src/toasty.service';
import { Message } from 'primeng/components/common/message';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent {

  checked = false;
  jwtPayload: any;
  display: boolean = false;
  versao: string;
  msgs: Message[] = [];
  
  constructor(
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router) { 
      this.versao = `${environment.version}`;
    }

  recupera(email: string) {
    this.auth.recuperaSenha(email)
      .then((response) => {
          this.router.navigate(['/']);
          this.toasty.success(response.msg)
        })
      .catch( erro => {
          this.errorHandler.handle(erro.error.msg);
          this.toasty.error(erro.error.msg)
        })
    }

    voltar() {
      this.router.navigate(['/']);
    }

    
}
