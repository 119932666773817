import { FormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  template: `
    <div *ngIf="temErro()" class="ui-message ui-messages-error">
      * Obrigratório!
    </div>
  `,
  styles: [`
    .ui-messages-error {
      border: 0;
      font-size: 10px;
      color: red;
      background-color: transparent;
    }
  `]
})
export class MessageComponent {

  @Input() error: string;
  @Input() control: FormControl;
  @Input() text: string;

  temErro(): boolean {
    return (this.control.hasError(this.error) && this.control.touched);
  }

}
