import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { LogoutService } from 'src/app/seguranca/logout.service';
import { ErrorHandlerService } from '../error-handler.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html'
})
export class HeaderInnerComponent implements OnInit {

  agencia : string;
  usuario : string;
  matricula : string;

  constructor(
    private auth: AuthService,
    private logoutService: LogoutService,
    private errorHandler: ErrorHandlerService,
    private router: Router) {}

  ngOnInit(): void {
   this.agencia = this.auth.jwtPayload.agencia;
   this.usuario = this.auth.jwtPayload.nome;
   this.matricula = this.auth.jwtPayload.matricula;
  }
  
  

  logout() {
    this.logoutService.logout()
    .then(() => {
       this.router.navigate(['']);
    })
    .catch( erro => this.errorHandler.handle(erro));
  }
 


}
