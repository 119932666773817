import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { LayoutStore } from 'angular-admin-lte';

@Component({
  selector: 'app-sidebar-left-inner',
  templateUrl: './sidebar-left-inner.component.html'
})
export class SidebarLeftInnerComponent implements OnInit {

  menuPermitido = [];

  constructor(
    private auth: AuthService,
    private layoutStore: LayoutStore) { }

  ngOnInit() {

    this.verifica();
    this.layoutStore.setSidebarLeftMenu(this.menuPermitido);
  }

  verifica() {

    this.menuPermitido.push(
      { label: 'DASHBOARD', route: '/home', iconClasses: 'fa fa-home' },
    )

    // CARTEIRAS
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS') ||
      this.auth.temPermissao('ROLE_COORDENADOR_POLICLINICA') ||
      this.auth.temPermissao('ROLE_COORDENADOR_ODONTOLOGIA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CARTEIRA') ||
      this.auth.temPermissao('ROLE_ACESSA_CARTEIRA_TIPO_SERVIDOR_PUBLICO')
    ) {
      this.menuPermitido.push(
        {
          label: 'Carteiras', route: 'carteiras', iconClasses: 'fa fa-address-card'
        },
      )
    }

    // CONSULTAS
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_INTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_EXTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CONSULTA_FISIOTERAPIA') ||
      this.auth.temPermissao('ROLE_PESQUISA_COORDENADOR_ATENDIMENTO_INTERNO')
    ) {
      this.menuPermitido.push(
        { label: 'Consultas - Agendar', iconClasses: 'fa fa-stethoscope', route: 'consultaMedica' }
      )
    }

    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS') ||
      this.auth.temPermissao('ROLE_PESQUISA_COORDENADOR_ATENDIMENTO_INTERNO') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_CONSULTA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_CONSULTA_EXAME')

    ) {
      this.menuPermitido.push(
        { label: 'Validar Consultas', iconClasses: 'fa fa-check-square', route: 'consultaMedica/validarConsulta' }
      )
    }

    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS') ||
      this.auth.temPermissao('ROLE_RELATORIO_COORDENADOR_ATENDIMENTO_INTERNO')
    ) {
      this.menuPermitido.push(
        { label: 'Relatorio Consultas Dia', iconClasses: 'pi pi-file', route: 'consultaMedica/relatorioConsultaDiaria' }
      )
    }

    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_CONSULTA') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_CONSULTA_EXAME')
    ) {
      this.menuPermitido.push(
        { label: 'Relatório Consultas Validadas', iconClasses: 'pi pi-file', route: 'consultaMedica/relatorioCredenciadoConsultas'
      }
      )
    }


    // *****************************************************************************************
    // *  EXAMES
    // *****************************************************************************************
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_INTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_EXTERNA') ||
      this.auth.temPermissao('ROLE_PESQUISA_EXAME_EXTERNA_LABORATORIO')
    ) {
      this.menuPermitido.push(
        { label: 'Exames - Agendar', iconClasses: 'fa fa-check-square', route: 'exame' },
      )
    }

    // VALIDACAO EXAME CREDENCIADO
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_EXAME') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_CONSULTA_EXAME')
      ){
      this.menuPermitido.push(
        {
          label: 'Validar Exames', iconClasses: 'fa fa-check-square', route: 'exame/validarExame'
        }
      )
    }

    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_EXAME') ||
      this.auth.temPermissao('ROLE_PESQUISA_CREDENCIADO_CONSULTA_EXAME')
    ) {
      this.menuPermitido.push(
        { label: 'Relatorio Exames Validados', iconClasses: 'pi pi-file', route: 'exame/relatorioCredenciadoExame'
      }
      )
    }

    // CADASTROS
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS')) {
      this.menuPermitido.push(
        {
          label: 'Cadastros', iconClasses: 'fa fa-cog',
          children: [
            { label: 'Profissional', route: 'profissional' },
            { label: 'Especialidade', route: 'especialidade' },
            { label: 'Orgão', route: 'orgao' },
            //{ label: 'Lotação', route: 'lotacao' },
            { label: 'Instituição de Saúde', route: 'instituicaoSaude' },
            { label: 'Agencia de Atendimento', route: 'agencia' },
            { label: 'Conselho de Classe', route: 'conselhoClasse' },
            { label: 'Procedimento', route: 'procedimentoambulatorial' },
            { label: 'Agrupamento', route: 'agrupamentoambulatorial' },
            { label: 'Grupo', route: 'grupoambulatorial' },
          ]
        },
      )
    }

    // RELATORIO
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS')
      ) {
      this.menuPermitido.push(
        {
          label: 'Relatórios', iconClasses: 'pi pi-file',
          children: [
            { label: 'Consulta',
              children: [
                { label: 'Autorizadas', route: 'consultaMedica/relatorioConsultasAutorizadas' },
                { label: 'Validadas', route: 'consultaMedica/relatorioConsultasValidadas' },
                { label: 'Autorizados Totais', route: 'consultaMedica/relatorioConsultasAutorizadasTotais' }
              ]
            },

            { label: 'Exames',
              children: [
                { label: 'Autorizados', route: 'exame/relatorioExamesAutorizados' },
                { label: 'Validados', route: 'exame/relatorioExamesValidados' },
                { label: 'Autorizados Totais', route: 'exame/relatorioExamesAutorizadosTotais' }
              ]
            },

            { label: 'Ficha Médica', route: '#' },
            { label: 'Especialidade', route: 'especialidade/relatorios' },
            // { label: 'Lotação', route: '#' },
            {
              label: 'Produtividade',
              children: [
                { label: 'Consulta', route: '#' },
                { label: 'Exames', route: '#' }
              ]
            },
            { label: 'Instituição de Saúde', route: 'instituicaoSaude/relatorios' },
            { label: 'Profissionais',
              children: [
                { label: 'Internos', route: '#' },
                { label: 'Credenciados', route: '#' },
                { label: 'Limites Credenciados', route: '#' },
                { label: 'Hospitais', route: '#' }
              ]
            },
            { label: 'Procedimentos', route: 'procedimentoambulatorial/relatorios' },
            { label: 'Extrato Geral', route: '#' },
            { label: 'Limites Mensais', route: '#' },
          ]
        },
      )
    }

    // USUARIOS DO SISTEMA
    if (
      this.auth.temPermissao('ROLE_ADMINISTRADOR') ||
      this.auth.temPermissao('ROLE_CHEFE_GABINETE') ||
      this.auth.temPermissao('ROLE_COORDENADOR_IASS') ||
      this.auth.temPermissao('ROLE_PESQUISA_USUARIO')) {
      this.menuPermitido.push(
        { label: 'Usuarios Sistemas', route: 'usuario', iconClasses: 'fa fa-users' },
      )
    }

    if (this.auth.temPermissao('ROLE_MUDAR_SENHA')) {
      this.menuPermitido.push(
        { label: 'Alterar Senha', route: 'alterarSenha', iconClasses: 'fa fa-key' },
      )
    }

  }
}
